import React from "react"
import BackgroundImage from "gatsby-background-image"
import styles from "./index.module.scss"

export default function ArticleGrid({ images }) {
  return (
    <div className="article-container md:h-auto">
      <div className="flex md:flex-col">
        <article
          className={`article ${styles.lg} w-8/12 md:w-full h-full border-white border-solid border-b-4 border-r-2 flex relative md:border-r-0 ${styles.article} ${styles.articleWisdom}`}
        >
          <div className="absolute w-full h-full top-0 left-0">
            <BackgroundImage
              Tag="div"
              className="w-full h-full"
              fluid={images[9].node.childImageSharp.fluid}
              backgroundColor={`#040e18`}
              loading="eager"
              critical={true}
            />
          </div>
          <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
            <h5 className="bold-font">Embrace Wisdom</h5>
            <h4 className="light-font md:text-xl">
              BMW Brand stories, Bimmer's stories, Content competition page
            </h4>
          </div>
        </article>
        <div className="flex w-4/12 flex-col h-full md:flex-row md:w-full">
          <article
            className={`article md:w-1/2 border-white border-solid border-l-2 border-b-4 md:border-l-0 md:border-r-2 flex relative ${styles.article} `}
          >
            <div className="absolute w-full h-full top-0 left-0">
              <BackgroundImage
                Tag="div"
                className="w-full h-full"
                fluid={images[9].node.childImageSharp.fluid}
                backgroundColor={`#040e18`}
                loading="eager"
                critical={true}
              />
            </div>
            <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
              <h5 className="bold-font">Embrace Progress</h5>
              <h4 className="light-font md:text-xl">
                90s with BMW Professional content
              </h4>
            </div>
          </article>
          <article
            className={`article md:w-1/2 border-white border-solid border-l-2 border-b-4 flex relative ${styles.article}`}
          >
            <div className="absolute w-full h-full top-0 left-0">
              <BackgroundImage
                Tag="div"
                className="w-full h-full"
                fluid={images[9].node.childImageSharp.fluid}
                backgroundColor={`#040e18`}
                loading="eager"
                critical={true}
              />
            </div>
            <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
              <h5 className="bold-font">Embrace Inspiration</h5>
              <h4 className="light-font md:text-xl">
                Sports events recuritment page, Sport event recaps
              </h4>
            </div>
          </article>
        </div>
      </div>
      <div className="flex md:flex-wrap">
        <article
          className={`article w-4/12 md:w-1/2 h-full border-white border-solid border-b-4 border-r-2 flex relative ${styles.article}`}
        >
          <div className="absolute w-full h-full top-0 left-0">
            <BackgroundImage
              Tag="div"
              className="w-full h-full"
              fluid={images[9].node.childImageSharp.fluid}
              backgroundColor={`#040e18`}
              loading="eager"
              critical={true}
            />
          </div>
          <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
            <h5 className="bold-font">Embrace Adventure</h5>
            <h4 className="light-font md:text-xl">Drive along content</h4>
          </div>
        </article>
        <article
          className={`article w-4/12 md:w-1/2 h-full border-white border-solid border-b-4 border-l-2 border-r-2 md:border-r-0 flex relative ${styles.article}`}
        >
          <div className="absolute w-full h-full top-0 left-0">
            <BackgroundImage
              Tag="div"
              className="w-full h-full"
              fluid={images[9].node.childImageSharp.fluid}
              backgroundColor={`#040e18`}
              loading="eager"
              critical={true}
            />
          </div>
          <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
            <h5 className="bold-font">Embrace Freedom</h5>
            <h4 className="light-font md:text-xl">
              Community event (e.g. drive and dine)
            </h4>
          </div>
        </article>
        <article
          className={`article w-4/12 md:w-full h-full border-white border-solid border-b-4 border-l-2 md:border-l-0 flex relative ${styles.article} ${styles.articleIndependence}`}
        >
          <div className="absolute w-full h-full top-0 left-0">
            <BackgroundImage
              Tag="div"
              className="w-full h-full"
              fluid={images[9].node.childImageSharp.fluid}
              backgroundColor={`#040e18`}
              loading="eager"
              critical={true}
            />
          </div>
          <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
            <h5 className="bold-font">Embrace Independence</h5>
            <h4 className="light-font md:text-xl">
              #MYBMWDAY (UGC content reposting page)
            </h4>
          </div>
        </article>
      </div>
      <div className="flex md:flex-wrap">
        <article
          className={`article w-4/12 md:w-1/2 h-full border-white border-solid border-b-4 border-r-2 flex relative ${styles.article}`}
        >
          <div className="absolute w-full h-full top-0 left-0">
            <BackgroundImage
              Tag="div"
              className="w-full h-full"
              fluid={images[9].node.childImageSharp.fluid}
              backgroundColor={`#040e18`}
              loading="eager"
              critical={true}
            />
          </div>
          <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
            <h5 className="bold-font">Embrace Adventure</h5>
            <h4 className="light-font md:text-xl">Drive along content</h4>          
          </div>
        </article>
        <article
          className={`article w-4/12 md:w-1/2 h-full border-white border-solid border-b-4 border-l-2 border-r-2 md:border-r-0 flex relative ${styles.article}`}
        >
          <div className="absolute w-full h-full top-0 left-0">
            <BackgroundImage
              Tag="div"
              className="w-full h-full"
              fluid={images[9].node.childImageSharp.fluid}
              backgroundColor={`#040e18`}
              loading="eager"
              critical={true}
            />
          </div>
          <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
            <h5 className="bold-font">Embrace Freedom</h5>
            <h4 className="light-font md:text-xl">
              Community event (e.g. drive and dine)
            </h4>
          </div>
        </article>
        <article
          className={`article w-4/12 md:w-full h-full border-white border-solid border-b-4 border-l-2 md:border-l-0 flex relative ${styles.article} ${styles.articleIndependence}`}
        >
          <div className="absolute w-full h-full top-0 left-0">
            <BackgroundImage
              Tag="div"
              className="w-full h-full"
              fluid={images[9].node.childImageSharp.fluid}
              backgroundColor={`#040e18`}
              loading="eager"
              critical={true}
            />
          </div>
          <div className="text-container text-white self-end z-10 px-12 pb-12 xl:pb-6 xl:px-6">
            <h5 className="bold-font">Embrace Independence</h5>
            <h4 className="light-font md:text-xl">
              #MYBMWDAY (UGC content reposting page)
            </h4>
          </div>
        </article>
      </div>
    </div>
  )
}
