import React from "react"
import { Link } from "gatsby"
import styles from './index.module.scss'

export default function Button({children, link}) {
  return (
    <Link
      to={link}
      className={`${styles.button} bg-brand-blue text-white block xxl:w-auto px-12 xxl:px-20 py-4 xxl:py-6 border mb-16 text-center hover:bg-white transition-all ease-linear duration-200`}
    >
      {children}
    </Link>
  )
}
